<template>
  <v-container class="fill-height">
    <v-card class="fill-height w-100 overflow-hidden d-flex flex-column" rounded="lg">
      <v-app-bar elevation="2" class="flex-grow-0 flex-shrink-1" rounded="0" color="primary" dark>
        <v-toolbar-title class="text-uppercase">{{ $tc("models.chat", 2) }}</v-toolbar-title>
      </v-app-bar>
      <v-row no-gutters class="fill-heigth flex-1">
        <v-col
          md="3"
          :class="[{ 'd-none': $route.name != 'Chats' }, 'flex-grow-1 flex-shrink-0 d-md-block']"
          style="border-right: 1px solid #0000001f">
          <v-list subheader class="py-0">
            <v-list-item>
              <user-select @input="userSelected($event)"></user-select>
            </v-list-item>
            <v-divider />
            <div v-for="(chat, index) in sortedChats" :key="index">
              <chat-tile :chat="chat"></chat-tile>
              <v-divider class="my-0" />
            </div>
          </v-list>
        </v-col>
        <v-col cols="12" md="9" :class="[{ 'd-none': !$route.name == 'Chat' }, 'd-md-block flex-grow-1 flex-shrink-0']">
          <router-view @newChat="newChat"></router-view>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { addToCacheArray } from "../apollo/helpers";
import UserSelect from "../components/inputs/UserSelect.vue";
import ChatTile, { CHAT_TILE_FRAGMENT } from "../components/chat/ChatTile.vue";

import _ from "lodash";

const CHATS_QUERY = gql`
  query Chats {
    me {
      id
      chats {
        id
        ...chatTile
      }
    }
  }
  ${CHAT_TILE_FRAGMENT}
`;

export default {
  name: "Chats",
  components: { UserSelect, ChatTile },
  props: { chat_id: String },
  data() {
    return {
      chats: [],
    };
  },
  apollo: {
    chats: {
      query: CHATS_QUERY,
      update: (data) => data.me.chats,
    },
  },
  computed: {
    sortedChats() {
      return _.orderBy(this.chats, "latestMessage.created_at", "desc");
    },
  },
  methods: {
    selectChat(value) {
      if (value == this.chat_id) {
        this.$router.push({ name: "Chats" });
      }
      this.$router.push({ name: "Chat", params: { chat_id: value } });
    },

    userSelected(user) {
      if (this.$route.params.user_id != user.id) this.$router.push({ name: "NewChat", params: { user_id: user.id } });
    },

    newChat(newChat) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: CHATS_QUERY,
        },
        queryField: "me.chats",
        newElement: newChat,
        unshift: true,
      });

      this.$router.push({ name: "Chat", params: { chat_id: newChat.id } });
    },
  },
};
</script>

<style scoped>
.chat-card {
  height: calc(100%);
}
</style>
