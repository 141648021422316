<template>
  <v-autocomplete
    v-model="selectedUser"
    @input="userSelected"
    :items="users"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    item-text="complete_name"
    label="Nuova chat"
    placeholder="Inizia a digitare per la ricerca..."
    prepend-icon="mdi-account"
    return-object
  >
  </v-autocomplete>
</template>

<script>
import gql from "graphql-tag";
import Vue from "vue";

export default {
  data() {
    return {
      selectedUser: null,
      search: null,
      users: [],
      page: 1,
      minSearchLength: 3,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    excludeTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isLoading() {
      return this.$apollo.queries.users.loading;
    },
  },
  methods: {
    userSelected(user) {
      this.$emit("input", user);
      Vue.nextTick(() => (this.selectedUser = null));
    },
  },
  apollo: {
    users: {
      query: gql`
        query GetUsers($filter: String, $active: Boolean, $excludeTypes: [String!]) {
          me {
            id
            client {
              id
              paginatedUsers(filter: $filter, withActiveStatus: $active, excludeTypes: $excludeTypes) {
                data {
                  id
                  complete_name
                }
              }
            }
          }
        }
      `,
      variables() {
        return { filter: this.search, active: this.active, excludeTypes: this.excludeTypes };
      },
      update: (data) => data.me.client.paginatedUsers.data,
      skip() {
        return (this.search?.length ?? 0) < this.minSearchLength;
      },
    },
  },
};
</script>
